<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <div class="page-header">
      <h1>{{ $t("PACKAGE") }}</h1>
      <b-button
        size="sm"
        variant="success"
        class="button-succes-style ml-2 float-right mb-2"
        @click="handleStore"
        ><font-awesome-icon icon="plus-circle" /> {{ $t("NEW") }}</b-button
      >
    </div>

    <storeModalVue @updateList="setup(true)"></storeModalVue>

    <div class="content">
      <div class="content-header">
        <searchBar
          :searchFunc="all_forfaits"
          :searchText="$t('FORFAIT_SEARCH')"
          :filtre="{
            with_default: filtre_default,
            entreprise: filtre_entreprise ? filtre_entreprise.id : null,
          }"
          :showFiltre="false"
          @returnValue="updateSearch($event)"
        ></searchBar>

        <b-modal id="entrepriseModal" ref="entrepriseModal">
          <template #modal-header="{ close }">
            <h5>{{ $t("COMPANY_ID") }}</h5>
            <b-button size="sm" @click="close()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.028"
                height="17.028"
                viewBox="0 0 17.028 17.028"
              >
                <path
                  id="Icon_material-close"
                  data-name="Icon material-close"
                  d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                  transform="translate(-7.5 -7.5)"
                  fill="#393939"
                />
              </svg>
            </b-button>
          </template>

          <form @submit.prevent="hideModal('entrepriseModal')">
            <div class="center">
              <b-form-group :label="$t('COMPANY_ID')" v-if="isSuperAdmin">
                <searchInput
                  :list="getAllentreprises"
                  :loader="getentrepriseLoading"
                  label="lib"
                  :searchFunc="all_entreprises"
                  @searchfilter="selectentreprise($event)"
                ></searchInput>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox
                  v-model="filtre_default"
                  :value="1"
                  :unchecked-value="0"
                >
                  {{ $t("Afficher les forfaits publiques") }}
                </b-form-checkbox>
              </b-form-group>
            </div>
          </form>
          <template #modal-footer>
            <div class="actionModel">
              <b-button variant="success" @click="setup(true)">
                <div class="block-spinner">
                  {{ $t("Appliquer") }}
                </div>
              </b-button>
            </div>
          </template>
        </b-modal>
      </div>
      <div class="filtre">
        <p class="table-count">{{ getTotalRowforfait }} {{ $t("FORFAIT") }}</p>
        <div class="productTrie" v-if="currententreprise.lib != null">
          {{ currententreprise.lib }}
          <font-awesome-icon
            icon="times-circle"
            class="icons"
            @click="
              filtre_entreprise = {
                lib: null,
              };
              setup(true);
            "
          />
        </div>
        <div class="productTrie" v-if="currentDefault == 1">
          Voir forfait publiques
          <font-awesome-icon
            icon="times-circle"
            class="icons"
            @click="
              filtre_default = null;
              setup(true);
            "
          />
        </div>
      </div>
      <div class="table-rapport-style w-100">
        <table>
          <thead style="border-radius: 5px 0px 0px 0px">
            <tr>
              <th>
                {{ $t("WORDING") }}
              </th>
              <th>
                {{ $t("REFERENCE") }}
              </th>
              <th style="border-radius: 0px 5px 0px 0px">
                {{ $t("VAT") }}
              </th>
              <th>
                {{ $t("TYPE_UNITE") }}
              </th>
              <th>
                {{ $t("Nomb_Unité") }}
              </th>
              <th>
                {{ $t("UNIT_PRICE") }}
              </th>
              <th class="action_head"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              role="row"
              class="ligneNormale"
              v-for="(data, i) in getAllforfaits"
              :key="i"
              :class="{ default_element: data.is_default }"
            >
              <td aria-colindex="2" role="cell">
                <font-awesome-icon
                  icon="fa-solid fa-bookmark"
                  class="icons"
                  v-if="data.is_default"
                />
                {{ data.lib }}
              </td>
              <td aria-colindex="5" role="cell">
                {{ data.ref }}
              </td>
              <td aria-colindex="8" role="cell">
                {{ data.tva }}
              </td>
              <td aria-colindex="8" role="cell">
                {{ data.type_unite }}
              </td>
              <td aria-colindex="8" role="cell">
                {{ data.quantite }}
              </td>
              <td aria-colindex="8" role="cell">
                {{ data.unit_price | financial }}
              </td>
              <td
                aria-colindex="10"
                role="cell"
                class="action_col"
                v-if="isSuperAdmin || isentrepriseAdmin || isAssistant"
              >
                <b-button
                  class="button-succes-style"
                  size="sm"
                  variant="danger"
                  @click.prevent.stop="handleDelete(data)"
                >
                  <font-awesome-icon icon="trash" />
                </b-button>
                <b-button
                  class="button-succes-style"
                  size="sm"
                  variant="success"
                  @click.prevent.stop="handleUpdate(data)"
                >
                  <font-awesome-icon icon="pencil-alt" />
                </b-button>
              </td>
              <td
                aria-colindex="10"
                role="cell"
                class="action_col"
                v-else-if="!data.is_default"
              >
                <b-button
                  class="button-succes-style"
                  size="sm"
                  variant="danger"
                  @click.prevent.stop="handleDelete(data)"
                >
                  <font-awesome-icon icon="trash" />
                </b-button>
                <b-button
                  class="button-succes-style"
                  size="sm"
                  variant="success"
                  @click.prevent.stop="handleUpdate(data)"
                >
                  <font-awesome-icon icon="pencil-alt" />
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <b-modal id="ModelConfigProduct" ref="ModelConfigProduct" scrollable>
        <template #modal-header>
          <h5>{{ $t("EDIT") }} {{ $t("FORFAIT") }}</h5>
          <b-button size="sm" @click="resetModal()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.028"
              height="17.028"
              viewBox="0 0 17.028 17.028"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                transform="translate(-7.5 -7.5)"
                fill="#393939"
              />
            </svg>
          </b-button>
        </template>

        <form @submit.prevent="forfaitModalNew" v-if="ProductToConfig">
          <div class="center">
            <b-form-group :label="$t('WORDING')" label-for="lib">
              <b-form-input
                id="lib"
                v-model="v$.ProductToConfig.lib.$model"
                :state="validateState('lib')"
                oninput="this.value = this.value.toUpperCase()"
                aria-describedby="lib-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.ProductToConfig.lib.$errors"
                id="lib-feedback"
              ></error-handle>
              <div v-if="erreurlist.lib" class="error-message">
                <ul v-if="Array.isArray(erreurlist.lib)">
                  <span v-for="(e, index) in erreurlist.lib" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.lib }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('REFERENCE')" label-for="reference ">
              <b-form-input
                id="reference"
                oninput="this.value = this.value.toUpperCase()"
                v-model="v$.ProductToConfig.ref.$model"
                :state="validateState('ref')"
                aria-describedby="ref-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.ProductToConfig.ref.$errors"
                id="ref-feedback"
              ></error-handle>
              <div v-if="erreurlist.ref" class="error-message">
                <ul v-if="Array.isArray(erreurlist.ref)">
                  <span v-for="(e, index) in erreurlist.ref" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.ref }}</span>
              </div>
            </b-form-group>
            <b-form-group :label="$t('VAT')" label-for="tva">
              <b-form-select
                v-model="v$.ProductToConfig.tva.$model"
                :options="optionsTVA"
                :state="validateState('tva')"
                aria-describedby="tva-feedback"
              ></b-form-select>
              <error-handle
                :list="v$.ProductToConfig.tva.$errors"
                id="tva-feedback"
              ></error-handle>
              <div v-if="erreurlist.tva" class="error-message">
                <ul v-if="Array.isArray(erreurlist.tva)">
                  <span v-for="(e, index) in erreurlist.tva" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.tva }}</span>
              </div>
            </b-form-group>

            <div class="modalforfait">
              <b-col md="4">
                <b-form-group
                  style="margin-bottom: 0"
                  :label="$t('TYPE_UNITE')"
                >
                  <b-form-select
                    v-model="v$.ProductToConfig.type_unite.$model"
                    :options="optionstype"
                    :state="validateState('type_unite')"
                    aria-describedby="type_unite-feedback"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group style="margin-bottom: 0" :label="$t('Quantité')">
                  <b-form-input
                    v-model="v$.ProductToConfig.quantite.$model"
                    Type="number"
                    step="1"
                    min="1"
                    :state="validateState('quantite')"
                    aria-describedby="quantite-feedback"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  style="margin-bottom: 0"
                  :label="$t('unit_price')"
                  label-for="nameunité"
                >
                  <b-form-input
                    id="nameunité"
                    v-model="v$.ProductToConfig.unit_price.$model"
                    Type="number"
                    min="1"
                    max="100000"
                    step="1"
                    :state="validateState('unit_price')"
                    aria-describedby="unit_price-feedback"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </div>
            <div v-if="erreurlist.type_unite" class="error-message">
              <ul v-if="Array.isArray(erreurlist.type_unite)">
                <span v-for="(e, index) in erreurlist.type_unite" :key="index">
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.type_unite }}</span>
            </div>
            <div v-if="erreurlist.quantite" class="error-message">
              <ul v-if="Array.isArray(erreurlist.quantite)">
                <span v-for="(e, index) in erreurlist.quantite" :key="index">
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.quantite }}</span>
            </div>
            <div v-if="erreurlist.unit_price" class="error-message">
              <ul v-if="Array.isArray(erreurlist.unit_price)">
                <span v-for="(e, index) in erreurlist.unit_price" :key="index">
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.unit_price }}</span>
            </div>
            <b-form-group :label="$t('DESC')" label-for="Description">
              <b-form-textarea
                id="desc"
                v-model="ProductToConfig.desc"
                :placeholder="$t('Description')"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
              <div v-if="erreurlist.desc" class="error-message">
                <ul v-if="Array.isArray(erreurlist.desc)">
                  <span v-for="(e, index) in erreurlist.desc" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.desc }}</span>
              </div>
            </b-form-group>
            <!-- <b-form-checkbox
              id="favoris"
              v-model="ProductToConfig.favoris"
              name="favoris"
              value="true"
              unchecked-value="false"
            >
              {{ $t("FAVORITE") }}
            </b-form-checkbox> -->
          </div>
        </form>
        <template #modal-footer>
          <div class="actionModel">
            <b-alert variant="warning" show v-if="errorU" class="messageError">
              {{ errorU }}
            </b-alert>
            <b-button
              variant="success"
              @click="forfaitModalNew"
              class="succes-btn-modal btn-success"
            >
              <div class="block-spinner">
                {{ $t("EDIT") }}
                <div v-if="getforfaitLoading" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </div>
            </b-button>
          </div>
        </template>
      </b-modal>

      <deleteModalVue
        :loader="getforfaitLoading"
        :elemToDelete="ProductToDelete"
        :elemDelete="delete_forfait"
        @updateList="setup(false)"
      ></deleteModalVue>

      <b-pagination
        v-if="search == ''"
        v-model="page"
        :total-rows="getTotalRowforfait"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
      <b-pagination
        v-else
        v-model="page"
        :total-rows="getTotalRowforfait"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
    </div>

    <div class="text-center center-spin">
      <b-spinner
        v-if="getforfaitLoading"
        variant="primary"
        label="Spinning"
        class="big-spin"
      ></b-spinner>
    </div>
    <CommentMarche to="/how-it-work/forfait"></CommentMarche>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import searchInput from "@/components/ui/searchInput";
import deleteModalVue from "../components/ui/deleteModal.vue";
import storeModalVue from "../components/Ajoutmodel/forfaitmodale.vue";
import formatMoney from "accounting-js/lib/formatMoney.js";
import searchBar from "../components/ui/searchBar.vue";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  maxLength,
  integer,
  numeric,
  minValue,
  maxValue,
} from "@vuelidate/validators";
import errorHandle from "../components/ui/errorHandle.vue";
import CommentMarche from "../components/commentMarche.vue";

export default {
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  components: {
    searchBar,
    searchInput,
    deleteModalVue,
    storeModalVue,
    errorHandle,
    CommentMarche,
  },
  data() {
    return {
      search: "",
      bodyFormData: new FormData(),
      ProductToConfig: {
        lib: null,
        ref: null,
        desc: null,
        type_unite: null,
        quantite: null,
        unit_price: null,
        tva: null,
        favoris: null,
      },
      erreurlist: {
        lib: null,
        ref: null,
        desc: null,
        entreprise_id: null,
        type_unite: null,
        quantite: null,
        unit_price: null,
        tva: null,
        favoris: null,
      },
      ProductToDelete: null,
      page: 1,
      perPage: 10,
      oldrefrence: null,
      optionsTVA: [
        { value: 0, text: "0%" },
        { value: 5.5, text: "5.5%" },
        { value: 10, text: "10%" },
        { value: 20, text: "20%" },
      ],
      optionstype: [
        { value: "m2", text: "m2" },
        { value: "ml", text: "ML" },
        { value: "h", text: "h" },
        { value: "forfait", text: this.$t("TypePrestation.Forfait") },
        { value: "unitaire", text: this.$t("unit_unitaire") },
      ],
      errorD: false,
      errorS: false,
      errorU: false,
      currententreprise: {
        id: null,
      },
      optionDefault: [
        { value: null, text: "cacher forfaits publics" },
        { value: 1, text: "Voir forfaits publics" },
      ],
      currentDefault: null,
      filtre_default: null,
      filtre_entreprise: {
        id: null,
      },
    };
  },
  validations() {
    return {
      ProductToConfig: {
        lib: { required, maxLength: maxLength(100) },
        ref: { maxLength: maxLength(100) },
        quantite: {
          required,
          integer,
          minValue: minValue(1),
          maxValue: maxValue(1000),
        },
        type_unite: { required },
        unit_price: {
          required,
          numeric,
          minValue: minValue(1),
          maxValue: maxValue(100000),
        },
        tva: { required, numeric },
      },
    };
  },
  methods: {
    ...mapActions([
      "edit_forfait",
      "all_forfaits",
      "delete_forfait",
      "all_entreprises",
    ]),

    validateState(name) {
      const { $dirty, $error } = this.v$.ProductToConfig[name];
      return $dirty ? !$error : null;
    },

    updateSearch(s) {
      this.page = 1;
      this.search = s;
    },

    selectentreprise(query) {
      if (query) {
        this.filtre_entreprise = query;
      } else {
        this.filtre_entreprise = {
          lib: null,
        };
      }
      this.setup(true);
      this.hideModal("entrepriseModal");
    },

    async searchProd() {
      await this.all_forfaits({
        search: this.search,
        page: this.page,
        per_page: this.perPage,
        default: this.filtre_default,
        with_default: 1,
        entreprise: this.filtre_entreprise.id,
      });
    },
    resetModal() {
      this.erreurlist = {
        lib: null,
        ref: null,
        desc: null,
        entreprise_id: null,
        type_unite: null,
        quantite: null,
        unit_price: null,
        tva: null,
        favoris: null,
      };
      this.errorD = null;
      this.errorU = null;
      this.hideModal("ModelConfigProduct");
    },
    hideModal(ref) {
      this.$refs[ref].hide();
    },
    handleUpdate(PRO) {
      this.ProductToConfig = { ...PRO };
      this.oldrefrence = PRO.ref;
      this.$refs["ModelConfigProduct"].show();
    },
    handleDelete(PRO) {
      this.ProductToDelete = { ...PRO };
      this.$root.$emit("bv::show::modal", "ModelDelete");
    },
    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },
    async forfaitModalNew() {
      const isFormCorrect = await this.v$.ProductToConfig.$error;
      this.erreurlist = {
        lib: null,
        ref: null,
        desc: null,
        entreprise_id: null,
        type_unite: null,
        quantite: null,
        unit_price: null,
        tva: null,
        favoris: null,
      };
      this.errorU = null;
      if (isFormCorrect) return;
      const data = {
        id: this.ProductToConfig.id,
        lib: this.ProductToConfig.lib,
        ref: this.ProductToConfig.ref,
        desc: this.ProductToConfig.desc,
        type_unite: this.ProductToConfig.type_unite,
        quantite: this.ProductToConfig.quantite,
        unit_price: this.ProductToConfig.unit_price,
        tva: this.ProductToConfig.tva,
        favoris: this.ProductToConfig.favoris ? 1 : 0,
      };
      await this.edit_forfait(data)
        .then(() => {
          this.setup();
          this.hideModal("ModelConfigProduct");
          this.errorU = null;
        })
        .catch((err) => {
          if (this.isObject(err)) {
            for (const [key, value] of Object.entries(err)) {
              if (!this.erreurlist[key]) {
                this.erreurlist[key] = value;
              }
            }
          } else {
            this.errorU = err;
          }
        });
    },
    async ProductDelete() {
      await this.delete_forfait({
        id: this.ProductToDelete.id,
      })
        .then(() => {
          this.hideModal("ModelDeleteProduct");
          this.hideModal("ModelConfigProduct");
          this.errorD = null;
        })
        .catch((err) => {
          this.errorD = err;
        });
    },

    handleStore() {
      this.$root.$emit("bv::show::modal", "forfaitModal");
    },

    async pagination(paginate) {
      this.page = paginate;
      var data = {
        search: this.search,
        page: this.page,
        per_page: this.perPage,
        with_default: this.filtre_default,
        entreprise: this.filtre_entreprise.id,
      };

      if (this.filtre_default == 1) data.with_default = this.filtre_default;

      await this.all_forfaits(data);
    },

    setup(refresh = false) {
      if (refresh) this.page = 1;
      this.search = null;
      this.currentDefault = this.filtre_default;
      this.currententreprise = this.filtre_entreprise;
      this.$refs["entrepriseModal"].hide();

      if (this.isSuperAdmin) {
        this.all_forfaits({
          page: this.page,
          per_page: this.perPage,
          with_default: this.filtre_default,
          entreprise: this.filtre_entreprise.id,
        });
      } else {
        this.all_forfaits({
          page: this.page,
          per_page: this.perPage,
          entreprise: this.getOnlineUser.entreprise.id,
          with_default: this.filtre_default,
        });
      }
    },
  },

  mounted() {
    this.setup(true);

    if (this.isSuperAdmin || this.isCommercialBatigo) {
      this.all_entreprises({ page: 1, per_page: 100 });
    }
  },

  computed: {
    ...mapGetters([
      "getAllforfaits",
      "getAllentreprises",
      "getentrepriseLoading",
      "getforfaitLoading",
      "getTotalRowforfait",
      "getTabs",
      "getOnlineUser",
    ]),
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    isCommercialBatigo() {
      return this.getOnlineUser.role == "user.commercial-batigo";
    },
    isAssistant() {
      return this.getOnlineUser.role == "user.assistant";
    },
    computedAllentreprises() {
      // var list = [
      //   {
      //     lib: "TOUTES LES ENTREPRISES",
      //     id: null,
      //   },
      // ];
      // if (this.getAllentreprises)
      //   this.getAllentreprises.forEach((element) => {
      //     if (element.id != 1) list.push(element);
      //   });
      return this.getAllentreprises;
    },
  },

  filters: {
    financial: function (value) {
      return formatMoney(value, {
        symbol: "€",
        thousand: " ",
        precision: 2,
        decimal: ",",
        format: "%v %s",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.input-group .icon-balance {
  top: 8px;
}
.body {
  padding: 4px 0;
  box-shadow: 1px 1px 8px #00000019;
  max-height: 60vh;
  overflow: auto;

  ul {
    list-style: none;
    & li {
      padding: 8px 0;
      cursor: pointer;
      background-color: #f8f8f8;
      margin-bottom: 4px;
      color: #000;
      border: none;
      box-shadow: none;
      transition: all 0.2s ease;
      text-align: center;

      &:hover {
        background-color: #28367a;
        color: #fff;
      }
    }
  }
}
.dropdown-item {
  text-align: center !important;

  .icon_trie {
    color: #28367a;
  }
}
.prodimg {
  max-width: 85px;
  max-height: 72px;
}
.content {
  & p {
    font-size: 12px;
    font-weight: 500;
    padding: 3px 0;
    margin: 0;
  }
}

.w-100 {
  background-color: #f8f8f8;
  padding: 4px;
  overflow-x: auto;
}

body {
  background: #fafdff;
}

.actionModel {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 5px 0;

  .messageError {
    color: #e4261b;
    margin: 0;
    font-size: 15px;
  }
  & .block-spinner {
    display: flex;
    justify-content: center;
  }

  & .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.detailClient {
  background-color: #f5f6f8;
  border-radius: 5px;

  .clientTop {
    display: flex;
    justify-content: space-between;

    .image {
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 1px 1px 24px #00000019;
      background-color: #fff;
      border-radius: 5px;
      text-align: center;
      position: relative;
      width: 55%;

      .star {
        position: absolute;
        top: 4px;
        right: 4px;
        font-size: 24px;
        color: #ffc400;
      }
    }

    .right {
      padding: 8px;
      box-shadow: 1px 1px 24px #00000019;
      background-color: #fff;
      border-radius: 5px;
      width: 42%;
    }

    & .prodimg {
      @media only screen and (max-width: 900px) {
        max-width: 100px;
        max-height: 100px;
      }
      max-width: 185px;
      max-height: 231px;
    }
  }

  .clientMid {
    box-shadow: 1px 1px 24px #00000019;
    border-radius: 5px;
    padding: 8px;
    background-color: #fff;
  }

  & h4 {
    color: #393939;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  & p {
    border: 1px solid #ced4da;
    border-radius: 5px;
    color: #393939;
    font-size: 14px;
    padding: 8px;
  }

  .desc {
    min-height: 120px;
    margin: 0;
  }
}

.body {
  padding: 15px;
  box-shadow: 1px 1px 24px #00000019;
  max-height: 60vh;
  overflow: auto;

  ul {
    list-style: none;
    & li {
      padding: 8px 0;
      cursor: pointer;
      background-color: #f8f8f8;
      margin-bottom: 4px;
      color: #000;
      border: none;
      box-shadow: none;
      transition: all 0.2s ease;
      text-align: center;

      &:hover {
        background-color: #28367a;
        color: #fff;
      }
    }
  }
}

.filtre {
  display: flex;
  align-items: center;

  .table-count,
  .productTrie {
    margin-right: 15px;
  }

  .productTrie {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    padding: 4px 8px;
    border-radius: 32px;
    background-color: #34c38f;
    color: #fff;

    .icons {
      cursor: pointer;
      width: 18px;
      height: 18px;
      margin-left: 4px;
    }
  }
}
.modalforfait {
  display: flex;
  align-items: center;
  width: 100%;
  .nbunite {
    white-space: nowrap;
  }
}
</style>
